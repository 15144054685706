import React from 'react'
import { Col, Row } from 'reactstrap'
import Button from '../../Button/Button'

const Contribution = (props) => {
  return (
    <Col className="mt-4" xs="12" style={{ width: '100%' }}>
      <Row style={{ position: 'relative', backgroundColor: 'rgba(24, 24, 24, 0.5)', padding: '2rem' }}>
        <span
          style={{
            position: 'absolute',
            right: '20px',
            top: '-0.8rem',
            background: 'rgb(8, 253, 216)',
            color: 'black',
            padding: '0 10px',
            borderRadius: '5px',
          }}
        >
          Contribution
        </span>
        <Col>
          <h6>
            {props.year}
            <hr style={{ background: '#08fdd8', width: '12%' }} />
          </h6>

          <h1>{props.title}</h1>
          <h6 style={{ color: '#616161' }}>{props.tech}</h6>
          <p className="mt-4" style={{ fontSize: '20px' }}>
            {props.info}
          </p>
          {props.github && (
            <a href={props.github} target="_blank" rel="noopener noreferrer">
              <Button text="Github" />
            </a>
          )}
        </Col>
      </Row>
    </Col>
  )
}

export default Contribution
