import React, { Component } from "react";
import { Container, Row, Col, Table, CardImg } from "reactstrap";
import ReactLogo from "../../assets/img/react-logo.svg";
import RailsLogo from '../../assets/img/rails-logo.png'
import './Skills.css'
import Loading from '../Loading/Loading'
import './Skills.css'
class Skills extends Component {
  state = {
    content: <Loading text="Fresh out the oven..." />,
  }

  componentDidMount() {
    setTimeout(
      () =>
        this.setState({
          content: (
            <Container
              className="d-flex"
              style={{
                height: '100%',
                width: '100%',
                flex: '1',
              }}
            >
              <Row className="mt-4">
                <Col className="mb-4" xs="12">
                  <h1 className="animated fadeInDown skills__title" style={{ fontSize: '72px', color: '#08fdd8' }}>
                    Skills & Experience
                  </h1>
                </Col>
                <Col className="mb-4" sm="12" md="6">
                  <p className="text-glow">
                    As a Senior Software Engineer, my expertise lies in full-stack web applications development, particularly
                    with Ruby on Rails.
                  </p>
                  <p className="text-glow">
                    In the realm of front-end development, I have a particular fondness for Tailwind CSS, appreciating its
                    utility-first approach for crafting responsive and visually appealing interfaces. My front-end strengths
                    are anchored in my proficiency with JavaScript and React, enabling me to create dynamic and user-friendly
                    web experiences.
                  </p>
                  <p className="text-glow">Here is a selection of relevant technologies that I am proficient in:</p>
                </Col>
                <Col className="logo-images-container" sm="6" xs="6">
                  <CardImg src={ReactLogo} style={{ maxWidth: '200px' }} alt="reactlogo" className="App-logo" />
                  <CardImg
                    src={RailsLogo}
                    style={{ maxWidth: '200px', objectFit: 'contain' }}
                    alt="railslogo"
                    className="Rails-logo"
                  />
                </Col>
                <div style={{ width: '100%' }}>
                  <Col className="animated fadeInUp fast table-container">
                    <Table borderless responsive style={{ overflow: 'auto' }}>
                      <thead>
                        <tr>
                          <th>
                            LANGUAGES <hr />
                          </th>
                          <th>
                            FRAMEWORKS <hr />
                          </th>
                          <th style={{ whiteSpace: 'nowrap' }}>
                            TOOLS & LIBRARIES <hr />
                          </th>
                          <th>
                            INTEGRATIONS <hr />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Ruby</td>
                          <td>Rails</td>
                          <td>Linux</td>
                          <td>Stripe</td>
                        </tr>
                        <tr>
                          <td>JavaScript (ES6)</td>
                          <td>React</td>
                          <td>Hotwire</td>
                          <td>Paypal</td>
                        </tr>
                        <tr>
                          <td>Python</td>
                          <td>Langchain</td>
                          <td>Stimulus</td>
                          <td>Facebook</td>
                        </tr>
                        <tr>
                          <td>SQL</td>
                          <td>Tailwind</td>
                          <td>Redux</td>
                          <td>Google</td>
                        </tr>
                        <tr>
                          <td className="empty"></td>
                          <td>Bootstrap</td>
                          <td>Docker</td>
                          <td>Apple</td>
                        </tr>
                        <tr>
                          <td className="empty"></td>
                          <td>Bulma</td>
                          <td>jQuery</td>
                          <td>OpenAI</td>
                        </tr>
                        <tr>
                          <td className="empty"></td>
                          <td>Sinatra</td>
                          <td>Webflow</td>
                          <td>Twitter (X)</td>
                        </tr>
                        <tr>
                          <td className="empty"></td>
                          <td className="empty"></td>
                          <td>Figma</td>
                          <td>Slack</td>
                        </tr>
                        <tr>
                          <td className="empty"></td>
                          <td className="empty"></td>
                          <td>Cloudflare</td>
                          <td>Zendesk</td>
                        </tr>
                        <tr>
                          <td className="empty"></td>
                          <td className="empty"></td>
                          <td>Excalidraw</td>
                          <td>Cloudinary</td>
                        </tr>
                        <tr>
                          <td className="empty"></td>
                          <td className="empty"></td>
                          <td>Notion</td>
                          <td>GoDaddy</td>
                        </tr>
                        <tr>
                          <td className="empty"></td>
                          <td className="empty"></td>
                          <td>Linear.app</td>
                          <td>Name.com</td>
                        </tr>
                        <tr>
                          <td className="empty"></td>
                          <td className="empty"></td>
                          <td>Jira</td>
                          <td>Gooten</td>
                        </tr>
                        <tr>
                          <td className="empty"></td>
                          <td className="empty"></td>
                          <td>HAML, SCSS, Lua</td>
                          <td>Easy Project</td>
                        </tr>
                        <tr>
                          <td className="empty"></td>
                          <td className="empty"></td>
                          <td>MySQL</td>
                          <td>Flowise</td>
                        </tr>
                        <tr>
                          <td className="empty"></td>
                          <td className="empty"></td>
                          <td>Postgres</td>
                          <td>Langflow</td>
                        </tr>
                        <tr>
                          <td className="empty"></td>
                          <td className="empty"></td>
                          <td>Animate.css</td>
                          <td>Streamlit</td>
                        </tr>
                        <tr>
                          <td className="empty"></td>
                          <td className="empty"></td>
                          <td>GC Functions</td>
                          <td>Web3</td>
                        </tr>
                        <tr>
                          <td className="empty"></td>
                          <td className="empty"></td>
                          <td className="empty"></td>
                          <td>Metamask</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </div>
              </Row>
            </Container>
          ),
        }),
      3000
    )
  }
  render() {
    return this.state.content
  }
}

export default Skills;
