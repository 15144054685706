import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import WorkItem from "./WorkItem/WorkItem";
import Contribution from './WorkItem/Contribution'
import hWAnimate from '../../assets/img/hw-animate.png'
import prism from '../../assets/img/prism.png'
import planetary from '../../assets/img/planetary.png'
import assista from '../../assets/img/assista.png'
import Loading from '../Loading/Loading'

class Work extends Component {
  state = {
    content: <Loading text="The fun stuff lives here." />,
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        content: (
          <div
            style={{
              width: '100%',
              height: '100%',
              background: '#222',
              zIndex: '1',
            }}
          >
            <Container
              className="d-flex"
              style={{
                height: '100%',
                width: '100%',
              }}
            >
              <Row>
                <Col xs="12" className="text-center mt-4">
                  <h1 style={{ fontSize: '72px', color: '#08fdd8' }}>Recent Work</h1>
                </Col>
                <div className="animated fadeInLeft">
                  <WorkItem
                    title="Assista"
                    year="2023"
                    tech="Rails 7 / OpenAI / TypeSense / Stripe / Langchain"
                    info="Assista offers a business-grade, no-code, customizable conversational AI platform designed to enhance customer interaction and streamline workflows."
                    link="https://assista.chat"
                    github="https://github.com/AssistaAI"
                    logo={assista}
                    extra="Artificial Intelligence"
                  />
                </div>
                <div className="animated fadeInRight" style={{ width: '100%' }}>
                  <Contribution
                    title="Langchainrb gem"
                    year="2023"
                    tech="Langchain.rb wraps all supported LLMs in a unified interface allowing you to easily swap out and test out different models."
                    github="https://github.com/andreibondarev/langchainrb"
                  />
                </div>
                <div className="animated fadeInLeft" style={{ width: '100%' }}>
                  <Contribution
                    title="Pagy gem"
                    year="2023"
                    tech="The Best Pagination Ruby Gem 🥇. Pagy is focused on performance and flexibility."
                    github="https://github.com/ddnexus/pagy"
                  />
                </div>
                 <div className="animated fadeInRight">
                  <WorkItem
                    title="Hotwire Animate"
                    year="2022"
                    tech="Rails 7 / Hotwire / Stimulus / Animate.css"
                    info="Taking the Hotwire approach we want to avoid writing JS whenever we can. The core goal of this project is to make it as easy as possible to sprinkle in animations whilst still supporting all Turbo Frame events."
                    link="https://hotwireanimate.com/"
                    github="https://github.com/Project-X-Repo/hotwire-animate"
                    npm="https://www.npmjs.com/package/hotwire-animate"
                    logo={hWAnimate}
                    extra="Built in 48 hours - Rails Hackaton"
                  />
                </div>
                <div className="animated fadeInLeft">
                  <WorkItem
                    title="Prism"
                    year="2022"
                    tech="React / NextJS / Web3"
                    info="Prism - a smart contract for a band's new album as a 100-copy limited edition with 20% of revenue going to charity."
                    link="https://prism-b75ae.web.app/"
                    github="https://github.com/antonzaharia/prism"
                    logo={prism}
                    extra="Web3"
                  />
                </div>
                <div className="animated fadeInRight">
                  <WorkItem
                    title="Planetary"
                    year="2022"
                    tech="React / NextJS / Web3"
                    info="Planetary - is a mini NFT platform designed for streamlined creation, sale, and exchange of digital artworks as NFTs."
                    link="https://planetary-19cc0.web.app"
                    github="https://github.com/antonzaharia/planetary"
                    logo={planetary}
                    extra="Web3"
                  />
                </div>
                <div className="animated fadeInRight delay-1s"></div>
                <div className="animated fadeInLeft delay-1s"></div>
              </Row>
            </Container>
          </div>
        ),
      })
    }, 2900)
  }

  render() {
    return this.state.content
  }
}

export default Work;
