import React, { Component } from "react";
import { Container, Col, Row } from "reactstrap";

import './Contact.css'

class Contact extends Component {

  render() {
    return (
      <Container>
        <div>
          <Row>
            <Col xs="12">
              <h1 className="contact-title">
                Contact Me
              </h1>
            </Col>
          </Row>
        </div>
        <div id="buttons-container">
          <a class="card" target="_blank" rel="noreferrer" href="mailto:zahariaanton95@gmail.com">
            <i class="far fa-envelope"></i>
            <span>Send email</span>
          </a>
          <a class="card" target="_blank" rel="noreferrer" href="tel:+447367111009">
            <i class="fas fa-phone"></i>
            <span>Call (UK number)</span>
          </a>
          <a
            href="https://twitter.com/messages/compose?recipient_id=1321853048793300997"
            class="card"
            target="_blank"
            rel="noreferrer"
            data-screen-name="@ZahariaAnton"
          >
            <i class="fab fa-twitter"></i>
            <span>DM on Twitter</span>
          </a>
          <a class="card" target="_blank" rel="noreferrer" href="https://m.me/Zaharia.Anton/">
            <i class="fab fa-facebook-f"></i>
            <span>DM on Facebook</span>
          </a>
          <a class="card" target="_blank" rel="noreferrer" href="https://calendly.com/zahariaanton/30min">
            <i class="far fa-calendar"></i>
            <span>Book a 30 minute meeting</span>
          </a>
        </div>
      </Container>
    )
  }
}

export default Contact;
