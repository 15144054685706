import React, { Component } from "react";
import { Container, Row, Col } from 'reactstrap'
import Loading from '../Loading/Loading'

const age = () => new Date().getFullYear() - new Date('March 31, 1995').getFullYear()

class About extends Component {
  state = {
    content: <Loading text="You're pretty awesome yourself." />,
  }

  componentDidMount() {
    setTimeout(
      () =>
        this.setState({
          content: (
            <Container className="d-flex align-items-center" style={{ height: '100%', width: '100%' }}>
              <Row>
                <Col xs="12">
                  <h1 className="mt-4 animated jackInTheBox" style={{ fontSize: '72px', color: '#08fdd8' }}>
                    About Me
                  </h1>
                </Col>
                <Col xs="12" sm="6">
                  <p
                    className="animated fadeInLeft delay-1s"
                    style={{ marginTop: '40px', fontSize: '24px', color: '#08fdd8' }}
                  >
                    Work passion
                  </p>
                  <p style={{ fontSize: '18px' }} className="animated jackInTheBox">
                    Hey there! I'm Anton Zaharia, a {age()}-year-old Full Stack Web Developer. I consider myself
                    uncomplicated and direct in my approach, I often get frustrated by exhaustive details and unnecessary
                    nuances. I love to create new, smart applications and percieve any problem as a challenge worth
                    overcoming.
                  </p>

                  <p style={{ fontSize: '18px' }} className="animated fadeInLeft delay-1s">
                    In my professional journey, I've been fortunate to receive accolades for my contributions. Such
                    recognition reflects my commitment to delivering exceptional results and my ability to tackle complex
                    challenges head-on. I thrive in collaborative environments, bringing a blend of technical expertise and
                    creative problem-solving to every project. My focus is not just on meeting expectations but exceeding
                    them, ensuring that every application I develop is not only functional but also innovative and
                    user-friendly. I believe in continuous learning and growth, constantly seeking new ways to refine my
                    skills and contribute more effectively to my team's success.
                  </p>
                </Col>
                <Col xs="12" sm="6" className="animated fadeInRight delay-1s">
                  <iframe
                    src="https://www.linkedin.com/embed/feed/update/urn:li:share:7135175685123272705"
                    height="700"
                    width="100%"
                    frameborder="0"
                    allowfullscreen=""
                    title="Embedded post"
                  ></iframe>
                </Col>
                <Col xs="12" style={{ width: '100%' }}>
                  <p
                    className="animated fadeInLeft delay-1s"
                    style={{ marginTop: '40px', fontSize: '24px', color: '#08fdd8' }}
                  >
                    Out of work passion
                  </p>
                  <p className="animated fadeInRight delay-1s" style={{ fontSize: '18px' }}>
                    The video below is with me practicing Parkour (Tricking actually). I used to do that because it was
                    making me stronger, faster, fitter, and more agile than I ever thought possible. It gave me increased
                    coordination, functional movement skills, and problem solving tools that crossed over into every other
                    aspect of my life.
                  </p>
                  <div className="animated fadeInLeft delay-1s">
                    <iframe
                      style={{ width: '100%' }}
                      width="auto"
                      height="450"
                      src={`https://www.youtube.com/embed/${'EywWngU5xDk'}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded youtube"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          ),
        }),
      3000
    )
  }
  render() {
    return this.state.content
  }
}

export default About;
