import React, { Component } from 'react'
import { Container, Row, Col, CardImg } from 'reactstrap'
import BrandLogo from '../../assets/img/logo.png'
import { Link } from 'react-router-dom'
import Button from '../Button/Button'
import Loading from '../Loading/Loading'
import './Home.css'

class Home extends Component {
  state = {
    content: <Loading text="Loading something special..." />,
  }

  componentDidMount() {
    setTimeout(
      () =>
        this.setState({
          content: (
            <Container style={{ width: '100%', height: '100%' }}>
              <Row
                className="d-flex justify-content-between align-items-center"
                style={{ width: '100%', minHeight: '100%' }}
              >
                <Col className="animated bounceInDown title__info" sm="12" md="8">
                  <h1>Anton Zaharia</h1>
                  <h2>
                    Senior Software Engineer
                  </h2>
                  <h6>Ruby on Rails and JavaScript - based developer</h6>
                  <div className="mt-4">
                    <Link to="/contact">
                      <Button text="CONTACT ME" />
                    </Link>
                  </div>
                </Col>
                <Col className="brandlogo" sm="12" md="4">
                  <CardImg className="animated flipInY delay-1s brandlogo__img" src={BrandLogo} alt="logo" />
                </Col>
              </Row>
            </Container>
          ),
        }),
      3000
    )
  }
  render() {
    return this.state.content
  }
}

export default Home
