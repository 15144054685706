import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import Loading from "../Loading/Loading";
import SimpleForum from '../../assets/img/SimpleForum.png'
import assista from '../../assets/img/assista.png'
import hwAnimate from "../../assets/img/hw-animate.png";
import aiqube from '../../assets/img/aiqube.png'
import prism from '../../assets/img/prism.png'
import planetary from '../../assets/img/planetary.png'
import tipjar from '../../assets/img/tipjar.png'
import asincly from '../../assets/img/asincly.png'
import movieApp from '../../assets/img/movie-app.png'
import todayihave from '../../assets/img/todayihave.png'
import csvs from '../../assets/img/csvs.png'
import YozuBlog from "../../assets/img/YozuBlog.png";
import ExperimentItem from "./ExperimentItem/ExperimentItem";
import ExperimentContainer from "./ExperimentContainer/ExperimentContainer";

import "./Experiments.css";

class Experiments extends Component {
  state = {
    content: <Loading text="Caution: Radioactivity ahead." />
  };

  componentDidMount() {
    setTimeout(
      () =>
        this.setState({
          content: (
            <Container
              className="d-flex align-items-center"
              style={{
                height: '100%',
                width: '100%',
                marginTop: '50px',
              }}
            >
              <Row style={{ width: '100%' }}>
                <Col xs="12">
                  <h1 className="mt-4 animated" style={{ fontSize: '72px', color: '#08fdd8' }}>
                    Experiments
                  </h1>
                </Col>
                <Col xs="12">
                  <p style={{ fontSize: '18px' }}>Sometimes I mess around in my free time...</p>
                </Col>

                <Col
                  style={{
                    marginTop: '150px',
                    height: '100%',
                    width: '100%',
                    zIndex: '2',
                  }}
                  xs="12"
                >
                  <ExperimentContainer>
                    <ExperimentItem link="https://lively-star-7477.fly.dev/" img={SimpleForum} />
                    <ExperimentItem link="https://app.assista.us" img={assista} />
                    <ExperimentItem link="https://hotwireanimate.com" img={hwAnimate} />
                    <ExperimentItem link="https://aiqube.co.uk/" img={aiqube} />
                  </ExperimentContainer>
                  <ExperimentContainer>
                    <ExperimentItem link="https://prism-b75ae.web.app/" img={prism} />
                    <ExperimentItem link="https://planetary-19cc0.web.app/" img={planetary} />
                    <ExperimentItem link="https://tipjar-d330e.web.app/" img={tipjar} />
                    <ExperimentItem link="https://asincly.vercel.app/" img={asincly} />
                  </ExperimentContainer>
                  <ExperimentContainer>
                    <ExperimentItem link="https://movie-app-ac360.web.app/" img={movieApp} />
                    <ExperimentItem link="https://todayihave.onrender.com/" img={todayihave} />
                    <ExperimentItem
                      link="https://yozu.co.uk/tech-blog-turbocharged-animations-introducing-hotwire-animate/"
                      img={YozuBlog}
                    />
                    <ExperimentItem link="https://csvs.fly.dev/" img={csvs} />
                  </ExperimentContainer>
                </Col>
              </Row>
            </Container>
          ),
        }),
      3000
    )
  }
  render() {
    return this.state.content;
  }
}
export default Experiments;
